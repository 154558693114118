import './App.css';
import React, {Suspense, useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
const Home = React.lazy(() => import("./pages/Home"));
const Company = React.lazy(() => import("./pages/Company"));
const Career = React.lazy(() => import("./pages/Career"));

const Technology = React.lazy(() => import("./pages/Technology"));
const Contactus = React.lazy(() => import("./pages/Contactus"));
const ServicesUi = React.lazy(() => import("./pages/ServicesUi"));

const ServicesSaas = React.lazy(() => import("./pages/ServicesSaas"));
const ServicesQA = React.lazy(() => import("./pages/ServicesQA"));
const MobileDevServices = React.lazy(() => import("./pages/MobileDevServices"));

const WebDevServices = React.lazy(() => import("./pages/WebDevServices"));

function App() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  
  return (
    <div>
      <Suspense fallback={<div>{ }</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/career" element={<Career />} />
          <Route path="/uiuxdesign" element={<ServicesUi />} />
          <Route path="/saasproducts" element={<ServicesSaas />} />
          <Route path="/qualityassurance" element={<ServicesQA />} />
          <Route path="/webappdevlopment" element={<WebDevServices />} />
          <Route path="/mobileappdevelopment" element={<MobileDevServices />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/contactus" element={<Contactus />} /> 
          {/* <Route path="*" element={<Pagenotfound />} /> */}
        </Routes>
      </Suspense>

    </div>
  );
}
export default App;
